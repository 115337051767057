import Wallet from '../../components/wallet';
import TaskView from '@/components/task';
import UI from "@/utils/ui";
import { HomeFilled, UserFilled } from "@element-plus/icons-vue";
export default {
  name: 'AppHeader',
  components: {
    UserFilled: UserFilled,
    HomeFilled: HomeFilled,
    Wallet: Wallet,
    TaskView: TaskView
  },
  data: function data() {
    return {
      menuSuss: false
    };
  },
  methods: {
    openMenu: function openMenu() {
      this.menuSuss = !this.menuSuss;
    },
    goTo: function goTo(to) {
      this.$router.push(to);
    },
    goBack: function goBack() {
      window.history.go(-1);
    }
  }
};