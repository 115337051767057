/** 语言包key值请参考vant3，网址：https://vant-contrib.gitee.io/vant/#/zh-CN/locale **/
export default {
    'zh-CN': {
        'copy-success': 'Copy successful!',
        'title': 'NFT市场',
        'mybuy': '我的认购',
        'buynum': '认购数量',
        'inviteNum': '共建数据',
        'inviteAmount': '共建流量',
        'gongxianusdt': '贡献usdt',
        'My Reward': '我的奖励',
    },
    'en-US': {
        'copy-success': 'Copy successful!',
        'title': 'NFT Market'
    },
    // ...
}
