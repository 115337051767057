import UI from '@/utils/ui';
import CommonFunction from "@/utils/commonFunction";
export default {
  name: 'AppFooter',
  data: function data() {
    return {
      CommonFunction: CommonFunction,
      active: null,
      tabs: [{
        name: 'DEMO',
        to: '/home',
        active: require('../../assets/images/tabs/tab-01-active.png'),
        inactive: require('../../assets/images/tabs/tab-01.png')
      }, {
        name: '文档',
        to: '/docs',
        active: require('../../assets/images/tabs/tab-02-active.png'),
        inactive: require('../../assets/images/tabs/tab-02.png')
      }]
    };
  },
  mounted: function mounted() {
    this.changeTab();
  },
  methods: {
    changeTab: function changeTab() {
      for (var i in this.tabs) {
        if (this.tabs[i].to == this.$route.path) {
          this.active = parseInt(i);
          break;
        }
      }
    },
    onChange: function onChange(idx) {
      if (this.tabs[idx].to.indexOf('https://') > -1) {
        window.location.href = this.tabs[idx].to;
        return;
      }

      if (this.$route.path != this.tabs[idx].to) {
        this.active = idx;
        this.$router.push(this.tabs[idx].to);
      }
    }
  }
};